import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import { RichTextRenderer } from '../components/rich-text-renderer'
import DateFormatter from '../components/date-formatter'
import ContactForm from '../components/contactForm'
import FadeIn from 'react-fade-in'
import Seo from '../components/seo'
import Helmet from 'react-helmet'
// import Bio from '../components/bio'
import ListenIcon from '../assets/icons/listen-icon.svg'
import logo from '../assets/images/logos/bradient-1920x1080.png';

import {
    blogPost,
    inFormComponent,
  } from '../components/layout.module.scss'
    const BlogPostTemplate = ({ data }) => {
    const post = data.webiny.getPost.data;
    const site = data.site.siteMetadata;
    
    return (
        <Layout>
        <Seo 
            title={`${post.title}`}
            description={`${post.description}`}
        />
        <Helmet>
            <meta property="og:image" content={`${post.featuredImage}`} />
            <meta property="fb:app_id" content='2970238806604187' />
            <meta property='og:locale' content='en_US' />
            <meta property='og:type' content='article' />
            <meta property='og:title' content={`${post.title}` + ' | ' + `${site.title}`} />
            <meta property='og:description' content={`${post.description}`} />
            <meta property='og:url' content={`${site.siteUrl}` + '/posts/' + `${post.slug}/`} />
            <meta property='og:updated_time' content={`${post.createdOn}`} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@bradient" />
            <meta name="twitter:creator" content="@bradient" />
            <meta property="og:url" content={`${site.siteUrl}` + '/posts/' + `${post.slug}/`} />
            <meta property="og:title" content={`${post.title}` + `${site.title}`} />
            <meta property="og:description" content={`${post.description}`} />
            <meta property="og:image" content={`${post.featuredImage}`} />
            <meta property='description' content={`${post.description}`} />
            <meta property='image' content={`${post.featuredImage}`} />

            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Article",
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "${site.siteUrl}/posts/${post.slug}/"
                        },
                        "headline": "${post.title}",
                        "alternativeHeadline": "${post.title}",
                        "image": "${post.featuredImage}",
                        "datePublished": "${post.datePublished}",
                        "dateModified": "${post.datePublished}",
                        "author": {
                            "@type": "Person",
                            "name": "${post.author.name}",
                            "image": "${post.author.picture}"
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "${site.title}",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "${site.siteUrl}${logo}"
                            }
                        },
                        "description": "${post.description}"${post.articleAudio ? `, "audio": {"@type": "AudioObject", "url": "${post.articleAudio}", "encodingFormat": "audio/mpeg"}`: ""}
                    }
                `}
            </script>
        </Helmet>
            <FadeIn delay={400} transitionDuration={900}>
                <div className='grid grid-cols-1 lg:grid-cols-12 gap-14'>
                    <div className='md:col-span-12 lg:col-span-8'>
                        <section>
                            <div className={blogPost}>

                                <div className='overflow my-4 lg:mt-2 lg:mb-5 border border-slate-100 inline-block'>
                                    <img src={post.featuredImage} className='w-full inline-block' alt={post.title} title={post.title} />
                                </div>

                                <header className="">
                                    <h1 className="text-2xl font-semibold my-4 text-black">{post.title}</h1>
                                    <span className="inline-block w-full text-xs mb-3"><DateFormatter dateString={post.datePublished} /></span>
                                    <div className='grid grid-cols-1 gap-3'>
                                        {/* <div className=''>
                                            <Bio />
                                        </div> */}
                                        <div className=''>
                                            <div className='float-left'>
                                                {post.articleAudio ?
                                                <div className="mb-5">
                                                    <div className='flex gap-4 items-center justify-left'>
                                                        {/* <img src={ListenIcon} className="h-5" /> */}
                                                        <h3 className=''>Listen</h3>
                                                        <audio controls>
                                                            <source src={post.articleAudio} />
                                                        </audio>
                                                    </div>
                                                </div>
                                                :
                                                null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </header>                                
                                <RichTextRenderer content={post.body} />
                            </div>
                        </section>
                        <div className="inline-block my-4 py-5 w-full">
                            <Link to="/posts">
                                <button className="text-center px-10 w-auto py-4 text-sm lg:text-base antialiased inline-block mt-5 text-md bg-bradient-blue border border-spacing-2 border-bradient-blue hover:bg-bradient-light hover:text-black text-white font-semibold transition duration-500 ease-in-out">← Go Back to Posts</button>
                            </Link>
                        </div>
                    </div>
                    <div className='md:col-span-12 lg:col-span-4'>
                        <div className={inFormComponent}>
                            <div className="float-right sticky top-24 inline-block">
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
            </FadeIn>
        </Layout>
    )
  }

export default BlogPostTemplate

export const pageQuery = graphql`
    query PostBySlug(
        $slug: String
    ) { 
        webiny {
            getPost(where: {slug: $slug}) {
                data {
                    id
                    title
                    slug
                    description
                    createdOn
                    datePublished
                    featuredImage
                    body
                    articleAudio
                    author {
                        name
                        picture
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                siteUrl
                description
            }
        }
    }
`;
  